var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex flex-column fill-height",attrs:{"fluid":""}},[_c('div',{on:{"click":_vm.showPermissionProductDialog}},[_c('v-card',{staticClass:"elevation-0 overflow-y-auto ma-auto",attrs:{"tile":"","flat":"","max-width":"90vw","height":"calc(89vh - 80px)","loading":_vm.loading}},[_c('v-card-title',{staticClass:"pa-2 mt-5"},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("common.userInfos"))+" ")]),_c('TooltipIcon',{attrs:{"right":"","color":"info","icon":"mdi-information","label":_vm.$t('userInformations.userInfosDescription')}})],1),_c('v-form',{ref:"form",staticClass:"mt-2 mb-5",attrs:{"disabled":!_vm.hasPermission},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0 pa-0"},_vm._l((_vm.contactTags),function(tag){return _c('v-col',{key:tag,staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"8","lg":"6"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7","sm":"5","md":"6","lg":"6","align-self":"center"}},[_c('v-card-text',{staticClass:"font-weight-medium body-2 py-0 pl-2 d-flex align-center"},[_vm._v(_vm._s(_vm.$t(_vm.userEditableTags[tag].translatePath))+" ")]),_c('v-card-text',{staticClass:"body-2 py-0 pl-2"},[_vm._v(_vm._s(_vm.userEditableTags[tag].label))])],1),_c('v-col',{staticClass:"text-left",attrs:{"align-self":"start","cols":"5","sm":"7","md":"12","lg":"6"}},[_c('v-text-field',{staticClass:"pt-0 pr-2 my-0 body-2",attrs:{"autocomplete":"off","hide-details":"auto","clearable":"","label":_vm.userEditableTags[tag].labelGoogle ||
                  _vm.$t(_vm.userEditableTags[tag].translatePath) ||
                  _vm.userEditableTags[tag].keyName,"rules":_vm.getRules(tag)},on:{"input":_vm.saveDataOnLocalStorage,"change":_vm.saveDataOnLocalStorage},model:{value:(_vm.tagsEdit[tag]),callback:function ($$v) {_vm.$set(_vm.tagsEdit, tag, $$v)},expression:"tagsEdit[tag]"}})],1)],1)}),1),_vm._l((_vm.employeeTags),function(tag,i){return _c('v-row',{key:i,staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12","sm":"","lg":"3","align-self":"center"}},[_c('v-card-text',{staticClass:"font-weight-medium body-2 py-0 pl-2 d-flex align-center"},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t(_vm.userEditableTags[tag].translatePath)))]),(tag === 'sign_photo')?_c('TooltipIcon',{attrs:{"right":"","color":"info","icon":"mdi-information","label":_vm.$t('userInformations.signaturePhotoUrlTooltipText')}}):_vm._e()],1),_c('v-card-text',{staticClass:"body-2 py-0 pl-2"},[_vm._v(_vm._s(_vm.userEditableTags[tag].label))])],1),_c('v-col',{staticClass:"text-left",attrs:{"align-self":"start","cols":"12","sm":"7","lg":"9"}},[(_vm.userEditableTags[tag].label === '[*USER_SIGN_PHOTO*]')?_c('div',[_c('v-avatar',{staticClass:"mb-4",attrs:{"size":"60"}},[(!_vm.tagsEdit[tag])?_c('img',{attrs:{"src":require("@/assets/user-default.png")}}):_c('img',{attrs:{"src":_vm.tagsEdit[tag]}})]),_c('v-text-field',{staticClass:"pt-0 pr-2 my-0 body-2",attrs:{"autocomplete":"off","clearable":"","label":_vm.userEditableTags[tag].label},on:{"input":function($event){return _vm.updateURL(tag)},"change":_vm.saveDataOnLocalStorage},model:{value:(_vm.tagsEdit[tag]),callback:function ($$v) {_vm.$set(_vm.tagsEdit, tag, $$v)},expression:"tagsEdit[tag]"}})],1):_c('v-text-field',{staticClass:"pt-0 pr-2 my-0 body-2",attrs:{"autocomplete":"off","clearable":"","label":_vm.userEditableTags[tag].labelGoogle ||
                _vm.$t(_vm.userEditableTags[tag].translatePath) ||
                _vm.userEditableTags[tag].keyName,"rules":[_vm.rules.maxUserInfo],"persistent-hint":_vm.userEditableTags[tag]?.hint ? true : false,"hint":_vm.$t(_vm.userEditableTags[tag].translateHintPath) ||
                _vm.userEditableTags[tag]?.hint},on:{"input":_vm.saveDataOnLocalStorage,"change":_vm.saveDataOnLocalStorage},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [(
                    _vm.userEditableTags[tag].label == '[*MEETING_SCHEDULE_LINK*]'
                  )?_c('div',{staticClass:"d-flex align-center",staticStyle:{"color":"#1948a3"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"#2196f3"}},[_vm._v("mdi-information")]),_c('span',{domProps:{"innerHTML":_vm._s(message)}})],1):_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.tagsEdit[tag]),callback:function ($$v) {_vm.$set(_vm.tagsEdit, tag, $$v)},expression:"tagsEdit[tag]"}}),(
                _vm.userEditableTags[tag].keyName == '[*USER_SIGN_PHOTO*]' &&
                _vm.notGoogleDriveUrl
              )?_c('v-alert',{staticClass:"d-flex align-center caption",attrs:{"dense":"","outlined":"","color":"warning"}}):_vm._e()],1)],1)}),_c('v-card-title',{staticClass:"pa-2 mt-5"},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("common.customizedInformation")))])]),_vm._l((_vm.userCustomTags),function(tag){return _c('v-row',{key:tag,staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3","align-self":"center"}},[_c('v-card-text',{staticClass:"font-weight-medium body-2 py-0 pl-2 d-flex align-center"},[_c('span',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.userEditableTags[tag].translatePath))+" ")]),(_vm.userEditableTags[tag].info)?_c('TooltipIcon',{attrs:{"right":"","color":"info","icon":"mdi-information","label":_vm.$t(_vm.userEditableTags[tag].infoTranslatePath)}}):_vm._e()],1),_c('v-card-text',{staticClass:"body-2 py-0 pl-2"},[_vm._v(_vm._s(_vm.userEditableTags[tag].label))])],1),_c('v-col',{staticClass:"text-left",attrs:{"align-self":"start","cols":"12","sm":"7","lg":"9"}},[_c('v-text-field',{staticClass:"pt-0 pr-2 my-0 body-2",attrs:{"data-testid":`${tag}`,"hide-details":"auto","autocomplete":"off","placeholder":_vm.$t(_vm.userEditableTags[tag].translatePlaceholderPath),"label":_vm.$t(_vm.userEditableTags[tag].translatePath),"clearable":""},on:{"input":function($event){return _vm.updateURL(tag)},"change":_vm.saveDataOnLocalStorage},model:{value:(_vm.tagsEdit[tag]),callback:function ($$v) {_vm.$set(_vm.tagsEdit, tag, $$v)},expression:"tagsEdit[tag]"}})],1)],1)}),_vm._l((_vm.customTagsEdit),function(tag,i){return _c('v-row',{key:i,staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"px-0 mx-0 d-flex align-end",attrs:{"cols":"12","sm":"4","lg":"3","align-self":"start"}},[_c('div',[_c('v-card-text',{staticClass:"font-weight-medium body-2 py-0 ml-1"},[_vm._v(_vm._s(tag.name)+" ")]),_c('v-card-text',{staticClass:"grey--text text--darken-2 body-2 py-0 ml-1",style:(_vm.$vuetify.breakpoint.mdAndDown && _vm.$vuetify.breakpoint.smAndUp
                    ? 'inline-size: 183px'
                    : '')},[_vm._v(_vm._s(tag.tag))])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"7","lg":"9","align-self":"end"}},[_c('v-text-field',{staticClass:"pt-0 my-0 body-2",attrs:{"placeholder":tag.name,"hide-details":"auto","color":"grey","clearable":"","rules":_vm.tagRules},on:{"input":function($event){_vm.$forceUpdate(), _vm.saveDataOnLocalStorage},"change":_vm.saveDataOnLocalStorage},model:{value:(tag.value),callback:function ($$v) {_vm.$set(tag, "value", $$v)},expression:"tag.value"}})],1)],1)})],2)],1)],1),_c('v-divider',{staticStyle:{"width":"90vw"}}),_c('v-footer',{staticClass:"border-bottom rounded-xl mt-3",staticStyle:{"height":"70px"},attrs:{"rounded":"","color":"background","width":"90vw"}},[_c('v-btn',{staticClass:"mx-auto text-none text-body-1 font-weight-medium px-8",staticStyle:{"border":"2px solid grey !important"},attrs:{"text":"","height":"52","color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"right":"","size":"22"},domProps:{"textContent":_vm._s('mdi-arrow-left')}}),_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")],1),_c('v-spacer'),_c('span',{attrs:{"v-show":false,"disabled":_vm.thereWereNoChanges}}),_c('div',[_c('v-btn',{staticClass:"mx-auto text-none text-body-1 font-weight-bold px-8 mr-0",attrs:{"height":"52","color":"accent","disabled":_vm.loading || !_vm.valid || !_vm.hasPermission,"loading":_vm.loading},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.$t("action.next"))+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"right":"","size":"22"},domProps:{"textContent":_vm._s('mdi-arrow-right')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }